<template>
  <div class="identity-proof">
    <div class="holder">
      <div class="box">
        <div class="icon-input flex align-center">
          <img src="@/assets/img/loginRegister/phone.png" alt="" />
          <p>用户注册手机号</p>
        </div>
        <input v-model.trim="phone" type="tel" placeholder="请输入手机号" maxlength="11"/>
      </div>
      <div class="box">
        <div class="icon-input flex align-center">
          <img src="@/assets/img/loginRegister/sms.png" alt="" />
          <p>验证码</p>
        </div>
        <input v-model.trim="smsCode" type="tel" placeholder="请输入验证码" maxlength="6"/>
        <p v-show="timeShow" class="code" @click="getCode">获取验证码</p>
        <p v-show="!timeShow" class="count">{{ count }}s</p>
      </div>
      <p :class="['next', { 'next-sure': (this.phone || this.phone === 0) && (this.smsCode || this.smsCode === 0) }]" @click="nextFun">
        下一步 重置密码
      </p>
    </div>
  </div>
  <toast-box v-if="showToast" :show-toast="showToast" :msg="msg" @sure="handleSure"></toast-box>
</template>
<script>
import ToastBox from "../../../components/toast.vue";
import { getverifycode } from "@/utils/loginRegister.js";
export default {
  name: "IdentityProof",
  components: { 
    ToastBox
  },
  data() {
    return {
      msg: "",
      count: "",
      timer: null,
      timeShow: true,
      phone: "",
      smsCode: "",
      showToast: false
    };
  },
  methods: {
    toLogin() {
      this.$router.push("/login")
    },
    handleSure(val) {
      this.showToast = val;
    },
    getCode() {
      if (!this.phone && this.phone !== 0) {
        return this.$toast("请输入手机号")
      } else if (!/^1\d{10}$/.test(this.phone)) {
        this.showToast = true;
        this.msg = "手机号填写错误<br>请填写11位正确的手机号";
        return
      } else {
        getverifycode({
          phone: this.phone,
        }).then((res) => {
          if (res && res.code == "0") {
            this.$toast.success("验证码已发送");
            this.code();
          }
        });
      }
    },
    //获取验证码倒计时
    code() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.timeShow = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.timeShow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    nextFun() {
      if (!this.phone && this.phone !== 0) {
        return
      } else if (!/^1\d{10}$/.test(this.phone)) {
        this.showToast = true;
        this.msg = "手机号填写错误<br>请填写11位正确的手机号";
        return
      } else if (!this.smsCode && this.smsCode !== 0) {
        return
      } else if (!/^\d{6}$/.test(this.smsCode)) {
        this.showToast = true;
        this.msg = "验证码填写错误<br>请重新填写正确的验证码";
        return
      }
      sessionStorage.setItem("identityProofInfo", JSON.stringify({
        phone: this.phone,
        smsCode: this.smsCode
      }))
      this.$router.push({
        path: "/inputProof"
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.identity-proof {
  height: 100vh;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
  .holder {
    .box {
      width: 315px;
      border-bottom: solid 1px #f6f6f6;
      padding: 30px 0 15px;
      margin: 0 auto;
      text-align: left;
      position: relative;
      .icon-input {
        height: 18px;
        img {
          width: 16px;
          height: 16px;
        }
        p {
          height: 18px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 18px;
          margin-left: 5px;
        }
      }
      .code {
        position: absolute;
        bottom: 14px;
        right: 0;
        width: 84px;
        height: 28px;
        -webkit-border-radius: 14px;
        -moz-border-radius: 14px;
        -ms-border-radius: 14px;
        -o-border-radius: 14px;
        border-radius: 14px;
        border: 1px solid #e62129;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #e62129;
        line-height: 28px;
        text-align: center;
      }
      .count {
        position: absolute;
        bottom: 14px;
        right: 0;
        width: 84px;
        height: 28px;
        -webkit-border-radius: 14px;
        -moz-border-radius: 14px;
        -ms-border-radius: 14px;
        -o-border-radius: 14px;
        border-radius: 14px;
        border: 1px solid #cccccc;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        text-align: center;
        line-height: 28px;
      }
    }
    input {
      height: 24px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      margin-top: 15px;
    }
    input::-webkit-input-placeholder {
      color: #cccccc;
    }
    .next {
      width: 315px;
      height: 44px;
      background: #e62129;
      -webkit-border-radius: 22px;
      -moz-border-radius: 22px;
      -ms-border-radius: 22px;
      -o-border-radius: 22px;
      border-radius: 22px;
      opacity: 0.5;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      line-height: 44px;
      margin: 30px auto;
    }
    .next-sure {
      opacity: 1;
    }
  }
}
</style>